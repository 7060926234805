<template>
  <div>
    <div class="topBanner1">
      <div class="countryStyle">
        {{ student_school }}
      </div>

      <div class="nameStyle">
        {{ student_name }}
      </div>

      <div class="showScoreStyle" @click="showScore()">View Results</div>
    </div>

    <div
      class="margin-left: 150px;margin-top: 50px;margin-right: 150px;background-color: #D9661F;"
    >
      <!-- <div class="student-session-title">我的项目</div>
      <div class="session-title-1">MY EVENT</div> -->
      <!-- //1061 × 882 -->
      <div class="lisbgimgview">
        <img
          src="../../../assets/newListBg.png"
          style="width: 100%; height: 100%; object-fit: fill"
        />
      </div>

      <div class="my-events">
        <div v-for="(item, index) in my_events" :key="index">
          <div class="events-item">
            <div style="position: absolute; z-index: 1; width: 100%">
              <img
                src="../../../assets/event_bg.png"
                style="width: 100%; height: 140px; margin-top: 10px"
              />
            </div>
            <div style="z-index: 2; padding: 0px 36px">
              <div class="event_name">
                {{ item.name }}
                <span class="type_label">{{ item.type + " Test" }}</span>
              </div>
              <div
                style="
                  position: relative;
                  margin-top: 10px;
                  height: 30px;
                  display: flex;
                  flex-direction: row;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      font-family: Montserrat-SemiBold;
                      color: #e5ac11;
                      font-size: 15px;
                      font-weight: 600;
                    "
                  >
                    Entry Time 
                  </div>
                  <div
                    style="
                      font-family: Montserrat-SemiBold;
                      color: #e5ac11;
                      font-size: 15px;
                      font-weight: 600;
                      margin-left: 10px;
                    "
                  >
                    {{ formatSessionDate(item.event_start_time ) }}
                                        <!-- {{ formatSessionDate(item.event_start_time - 3600 * 8) }} -->

                  </div>

                  <div
                    style="
                      margin-left: 50px;
                      font-family: Montserrat-SemiBold;
                      color: #e5ac11;
                      font-size: 15px;
                      font-weight: 600;
                    "
                  >
                    Start Time 
                  </div>
                  <div
                    style="
                      font-family: Montserrat-SemiBold;
                      color: #e5ac11;
                      font-size: 15px;
                      font-weight: 600;
                      margin-left: 10px;
                    "
                  >
                    {{ formatSessionDate(item.event_start_time ) }}
                                        <!-- {{ formatSessionDate(item.event_start_time - 3600 * 8) }} -->

                  </div>
                </div>
                <div class="buttonStyle">
                  <div class="event-status">
                    {{ status_en_labels[item.status] }}
                  </div>
                  <div class="event-entry" @click="goExam(item)">
                    {{ "&nbsp;&nbsp;Enter&nbsp;" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="bottomView">

    </div> -->
  </div>
</template>

<script>
import {
  checkIsUploadTest,
  getExerciseTestTemplate,
  getSessionEvents,
  getTeamMembers,
  getExamReadyTime,
  checkUserInvigilate
} from "../../../api/index";
import {
  getApplicationEvents,
  getApplicationExtraEvents,
  getProjectCode,
  getUserId,
  getUserName,
  getAccountName,
  setTestEvent,
} from "../../../utils/store";
import { formatDate } from "../../../utils/date";
import "../../../assets/common/font.css";
export default {
  name: "SessionList",
  data() {
    return {
      project_code: "",
      my_events: [],
      my_other_events: [],
      sessionList: [],
      login_application_extra_events: [],
      login_application_events: [],
      student_name: "",
      student_school: "",
      exam_entry_before_time: 0,
      exam_prohibit_time_when_start: 0,
      status_en_labels: {
        未开始: "Not Started",
        进行中: "Testing",
        已完成: "Complete",
        已结束: "Ended",
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });

    this.login_application_extra_events = getApplicationExtraEvents();
    this.login_application_events = getApplicationEvents();
    this.project_code = getProjectCode();
    this.fetchData();
    this.getTeamMembers();
    this.getReadyTime();
  },
  methods: {
    getReadyTime() {
      getExamReadyTime().then((res) => {
        let result = res.data.data;
        this.exam_entry_before_time = result.exam_entry_before_time * 60 * 1000;
        this.exam_prohibit_time_when_start =
          result.exam_prohibit_time_when_start * 60 * 1000;
      });
    },

    getTeamMembers() {
      getTeamMembers(this.project_code, getUserId()).then((res) => {
        var result = res.data.data;

        console.log(1111, result);

        this.student_name = result.team_names.join(" ");
        this.student_school = result.country;
      });
    },
    goExam(item) {
      var startTime = item.event_start_time;
      var temp1 = parseInt(startTime) * 1000;
      var currentTime = new Date().getTime();
      console.log("temp1 :" + temp1);
      console.log("currentTime :" + currentTime);

      var test_event = item.name;
      //TODO:为了测试，去掉时间判断

      if (item.type == "Formal") {
        if (temp1 - this.exam_entry_before_time > currentTime) {
          this.$message({
            message:
              "The test has not started. You can only enter 15 minutes before the start time",
            type: "warning",
          });
          return;
        }
        //考试开始15分钟，无法进入考场
        if (temp1 + this.exam_prohibit_time_when_start < currentTime) {
          this.$message({
            message:
              "The entry time is already over. You can not enter into the test room now.",
            type: "warning",
          });
          return;
        }
      }

      getExerciseTestTemplate(
        item.name,
        getProjectCode(),
        item.type,
        test_event
      ).then((res) => {
        var result = res.data.data;
        var template_id = result._id.$id;
        this.doCheck(template_id, temp1, item.type, test_event);
      });
    },
    showScore() {
      this.$router.push({
        path: "/award",
        query: {},
      });
    },
    doCheck(template_id, timeStr, event_type, event) {
      var student_id = getUserId();
      checkIsUploadTest(template_id, student_id, getProjectCode()).then(
        (res) => {
          var result = res.data;
          // result= false
          if (result) {
            this.$alert(
              "Please note that you have already completed this test. No re-entry is allowed.",
              "Reminder",
              {
                confirmButtonText: "OK",
                callback: () => {},
              }
            );
          } else {
            setTestEvent(event);


            //判断是否需要监考
            checkUserInvigilate(getProjectCode(),getUserId()).then((res) => {
              console.log(1111,res)
              //true 需要监考
              if (res.data == true){
                this.$router.push({
                  path: '/ready/exam',
                  query: {
                    template_id: template_id,
                    start_time:timeStr,
                    event_type:event_type
                  }
                })
              }else{

                this.$router.push({
                  path: "/exam",
                  query: {
                    template_id: template_id,
                    invigilateStatus:true
                  },
                });
              }
            })

            // this.$router.push({
            //   path: "/ready/exam",
            //   query: {
            //     template_id: template_id,
            //     start_time: timeStr,
            //     event_type: event_type,
            //   },
            // });
          }
        }
      );
    },
    formatSessionDate(time) {
      var time_1 = JSON.parse(time) * 1000;
      var date = new Date(time_1);
      return formatDate(date, "yyyy-MM-dd hh:mm");
    },
    fetchData() {
      // var events_arr = JSON.parse(this.login_application_events);
      // console.log(11112, events_arr);
      // if (events_arr.length > 0) {
        this.getSessionEvents();
      // }

      // getSessionList(this.project_code).then((res) => {
      //   var result = res.data.data
      //   var keys = Object.keys(result)
      //   for (var i = 0; i < keys.length; i++) {
      //     var key = keys[i]
      //     var session = {
      //       session_name: key,
      //       session_time: result[key]
      //     }
      //     this.sessionList.push(session)
      //   }
      //   var first_session_name = this.sessionList[0]['session_name']
      //   this.getSessionEvents(first_session_name)
      // })
    },
    getSessionEvents() {
      this.my_other_events = [];
      this.my_events = [];
      getSessionEvents(this.project_code, this.project_code, getUserId()).then((res) => {
        var result = res.data.data;

        // this.my_events = result[session];


        var result = res.data.data

        var resultKeys = Object.keys(result)
        console.log(111111,resultKeys)
        console.log(222222,result)

        for (var i = 0; i < resultKeys.length; i++) {
            var key = resultKeys[i]
            // if (key == 'Economics'){
            //   this.my_events = result[key]
            // }else{
              var eventArr = result[key]
              
              for (var j = 0; j < eventArr.length; j++) {

                var event = eventArr[j]
                this.my_events.push(event)

              }

            // }
        }

        // if (session == 'Economics') {
        //   this.my_events = result[session]
        // } else {
        //   if (this.login_application_events) {
        //     for (var i = 0; i < this.login_application_events.length; i++) {
        //       var event = this.login_application_events[i]
        //       if (result[event]) {//有数据
        //         var temp = result[event]
        //         for (var m = 0; m < temp.length; m++) {
        //           this.my_events.push(temp[m])
        //         }
        //       }
        //     }
        //   }
        // }

        // if (this.login_application_extra_events) {
        //   for (var j = 0; j < this.login_application_extra_events.length; j++) {
        //     var event1 = this.login_application_extra_events[j];
        //     if (result[event1]) {
        //       //有数据
        //       var temp1 = result[event1];
        //       for (var n = 0; n < temp1.length; n++) {
        //         this.my_other_events.push(temp1[n]);
        //       }
        //     }
        //   }
        // }
      });
    },
  },
};
</script>

<style scoped>
.buttonStyle {
  /* top: -20%; */
  transform: translateX(-30%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  margin-top: -46px;
  /* height: 80px; */
  /* background-color: #D9661F; */
}
.event-entry {
  /* font-family: Montserrat-SemiBold; */
  cursor: pointer;
  padding: 2px 5px;
  /* width: 50px; */
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  background: #e5ac11;
  /* text-align: center; */
  margin-top: 15px;
  /* border-radius: 5px; */
  /* border: 2px solid #6F69F7; */
  font-family: Montserrat-SemiBold;
}

.event-status {
  /* margin-top: px; */
  font-weight: 600;
  font-size: 18px;
  padding: 2px 5px;
  color: #218393;
  background: white;
  /* border-radius: 5px; */
  border: 1px solid #218393;
  font-family: Montserrat-SemiBold;
}

.event_name {
  font-family: Montserrat-SemiBold;
  font-size: 36px;
  font-weight: 600;
  color: #218393;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.events-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 80px;
}

.my-events {
  margin: 20px auto;
  width: 80%;
  /* margin-right: 150px; */
}

.student-session-title {
  font-weight: 600;
  color: #c4d7ff;
  font-size: 18px;
  font-family: Montserrat-SemiBold;
}

.session-title-1 {
  font-family: Montserrat-SemiBold;
  font-weight: 600;
  color: white;
  font-size: 36px;
}

.session-name {
  font-size: 28px;
  font-weight: 600;
  color: white;
  margin: 10px 0 0 45px;
  font-family: Montserrat-SemiBold;
}

.session-item {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 340px;
  height: 285px;
  background-image: url("../../../assets/session_nav_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.session-item-bottom {
  position: relative;
  height: 110px;
  margin-top: 30px;
  padding-left: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.session-time {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #f18601;
  font-weight: 600;
  font-family: Montserrat-SemiBold;
}

.sessionList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.type_label {
  font-size: 18px;
  margin-left: 10px;
}

.topBanner1 {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-repeat: no-repeat;
  background-color: #d7ecee;
  background-size: cover;
  color: #218393;
}
.bottomView {
  position: relative;
  /* z-index: 1; */
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-repeat: no-repeat;
  background-color: #d9661f;
  background-size: cover;
}
.lisbgimgview {
  position: absolute;
  z-index: 2;
  width: 265.25px;
  height: 220.5px;
  bottom: 0px;
}
.countryStyle {
  position: absolute;
  margin-top: -20px;
  margin-left: 150px;
  font-size: 20px;
  color: #218393;
  height: 25px;
  font-family: Montserrat-SemiBold;
}

.nameStyle {
  position: absolute;
  margin-top: 25px;
  margin-left: 150px;
  font-size: 13px;
  color: #218393;
  height: 25px;
  font-family: Montserrat-SemiBold;
}
.showScoreStyle {
  position: absolute;
  right: 200px;
  /* margin-right: 150px; */
  /* margin-top: 25px; */
  /* margin-left: 150px; */
  font-size: 13px;
  width: 120px;
  color: #218393;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border: 1px solid #218393;
  font-family: Montserrat-SemiBold;
}
</style>
